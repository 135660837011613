import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { contains } from 'ramda';
import { environment } from '../../environments/environment.devstage';

interface Permissions {
  roles: string[];
  permissions: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public Permissions!: Permissions;
  // Variables
  authUrl = environment.apiUrl + 'oauth/token';
  options: any;
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  /**
   * Constructor
   * @param http The http client object
   */
  constructor(private http: HttpClient) {
    this.options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };
  }
  /**
   * Get an access token
   * @param e The email address
   * @param p The password string
   */
  login(e: string, p: string) {
    return this.http
      .post(
        this.authUrl,
        {
          grant_type: 'password',
          client_id: environment.oAuthClientId,
          client_secret: environment.oAuthClientSecret,
          username: e,
          password: p,
          scope: '',
        },
        this.options
      )
      .pipe(
        map((data: any) => {
          this.Permissions = {
            permissions: data.permissions,
            roles: data.roles,
          };
          localStorage.setItem('Permissions', JSON.stringify(this.Permissions));
          // Store the access token in the localstorage
          localStorage.setItem('access_token', data.access_token);

          localStorage.setItem('user_id', data.user_id);
          localStorage.setItem('sector_id', data.sector);
          localStorage.setItem('department_id', data.department);

          this.isLoginSubject.next(true);
          return data;
        })
      );
  }

  /**
   * Get an access token
   * @param e The email address
   * @param p The password string
   */
  loginOneTimePassword(otp: string) {
    return this.http
      .post(
        this.authUrl,
        {
          grant_type: 'one_time_password',
          client_id: environment.oAuthClientId,
          client_secret: environment.oAuthClientSecret,
          one_time_password: otp,
          scope: '',
        },
        this.options
      )
      .pipe(
        map((data: any) => {
          this.Permissions = {
            permissions: data.permissions,
            roles: data.roles,
          };
          localStorage.setItem('Permissions', JSON.stringify(this.Permissions));
          // Store the access token in the localstorage
          localStorage.setItem('access_token', data.access_token);

          localStorage.setItem('user_id', data.user_id);
          localStorage.setItem('sector_id', data.sector);
          localStorage.setItem('department_id', data.department);

          this.isLoginSubject.next(true);
          return data;
        })
      );
  }
  /**
   * Revoke the authenticated user token
   */
  logout() {
    this.options.headers.Authorization =
      'Bearer ' + localStorage.getItem('access_token');

    this.isLoginSubject.next(false);

    return this.http.get(
      environment.apiUrl + 'api/user/logout',
      this.options
    );

    // return this.http.get(
    //   environment.apiUrl +
    //     (environment.useAzureLogin ? 'logout/azure' : 'api/user/logout'),
    //   this.options
    // );
  }

  public hasToken(): boolean {
    return !!localStorage.getItem('access_token');
  }

  checkRole(role: string) {
    if (!this.Permissions) {
      const pdata = localStorage.getItem('Permissions')
        ? localStorage.getItem('Permissions')
        : '';
      this.Permissions = pdata !== null ? JSON.parse(pdata) : null;
    }
    return contains(role, this.Permissions.roles);
  }

  getIsLoginSubject(): BehaviorSubject<boolean> {
    return this.isLoginSubject;
  }
}
