<div class="close-sidebar" (click)="toggleSidebar()">
  <fa-icon class="fal" [icon]="faTimes" size="2x"></fa-icon>
</div>

<dx-scroll-view width="100%" height="calc(100vh - 80px)">
  <div class="sidebar-menu sidebar-menu-top">
    <ul>
      <li
        (click)="toggleSidebar()"
        routerLink="/procedural-instructions/dashboard"
      >
        {{ "menu.proceduralInstructions" | translate }}
      </li>
      <li
        (click)="toggleSidebar()"
        [routerLink]="['/trainings', 'dashboard']"
        *ngIf="permissionGuard.hasPermission(['fe.myTrainings'])"
      >
        {{ "menu.myTrainings" | translate }}
      </li>
      <hr *ngIf="permissionGuard.hasPermission(['adminDashboard.show'])" />
      <li
        class="header"
        *ngIf="permissionGuard.hasPermission(['adminDashboard.show'])"
      >
        {{ "menu.administration" | translate }}
      </li>
      <li
        *ngIf="
          permissionGuard.hasPermission(['adminDashboard.show']) &&
          permissionGuard.hasPermission(['instruction.read'])
        "
        (click)="toggleSidebar()"
        routerLink="/procedural-instructions/admin/dashboard"
      >
        {{ "menu.administrationInstructions" | translate }}
      </li>
      <li
        *ngIf="
          permissionGuard.hasPermission(['adminDashboard.show']) &&
          permissionGuard.hasPermission(['training.read'])
        "
        (click)="toggleSidebar()"
        routerLink="/trainings/admin/dashboard"
      >
        {{ "menu.administrationTrainings" | translate }}
      </li>
      <!-- <li
        *ngIf="permissionGuard.hasPermission(['adminDashboard.show'])"
        (click)="toggleSidebar()"
        routerLink="/procedural-instructions/admin/userlist/dashboard"
      >
        {{ "menu.administrationUserlist" | translate }}
      </li> -->
      <li
        *ngIf="
          permissionGuard.hasPermission([
            'adminDashboard.show',
            'userList.byUserSector',
            'userList.byUserDepartment'
          ])
        "
        (click)="toggleSidebar()"
        routerLink="/procedural-instructions/admin/userlist/dashboard"
      >
        {{ "menu.administrationUserlistRSStatus" | translate }}
      </li>
      <li
        class="header"
        *ngIf="
          permissionGuard.hasPermission(['positionsQualificationsManager'])
        "
      >
        {{ "menu.management" | translate }}
      </li>
      <li
        *ngIf="
          permissionGuard.hasPermission(['positionsQualificationsManager'])
        "
        (click)="toggleSidebar()"
        routerLink="/procedural-instructions/admin/qualifications"
      >
        {{ "menu.administrationQualifications" | translate }}
      </li>
    </ul>
  </div>

  <div class="sidebar-menu sidebar-menu-bottom">
    <ul>
      <li>{{ "menu.privacy" | translate }}</li>
      <li>{{ "menu.imprint" | translate }}</li>
      <li (click)="logout()">{{ "menu.logout" | translate }}</li>
      <!-- <li (click)="adminLogout()">{{ "menu.adminLogout" | translate }}</li> -->
    </ul>
  </div>
</dx-scroll-view>
