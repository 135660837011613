<div class="container login-start" id="login">
  <div class="row">
    <div class="col-12">
      <form
        class="login-form"
        [formGroup]="form"
        (submit)="login()"
        *ngIf="!useAzureLogin"
      >
        <div class="login-card card">
          <h1 class="card-title">
            <strong>{{ "generic.login" | translate }}</strong>
          </h1>
          <div class="row" *ngIf="errors">
            <div class="col">
              <div
                class="alert alert-danger"
                [innerHTML]="'login.loginError' | translate"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  [attr.placeholder]="'generic.email' | translate"
                  class="form-control"
                />
              </div>
              <div class="form-group form-password">
                <input
                  type="password"
                  id="password"
                  formControlName="password"
                  [attr.placeholder]="'generic.password' | translate"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-light login-button"
          [disabled]="loading"
        >
          {{ "generic.login" | translate }}
        </button>
        <div class="login-info" [innerHTML]="'login.info' | translate"></div>
      </form>
      <form
        class="azure-login-form"
        [formGroup]="form"
        (submit)="loginAzure()"
        *ngIf="useAzureLogin"
      >
        <div class="login-card card">
          <h1 class="card-title">
            <strong>{{ "generic.login" | translate }}</strong>
          </h1>
          <div class="row" *ngIf="errors">
            <div class="col">
              <div
                class="alert alert-danger"
                [innerHTML]="'login.loginError' | translate"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              Mit Klick auf "Einloggen" werden Sie zu Microsoft weitergeleitet,
              um sich einzuloggen.
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-light login-button"
          [disabled]="loading"
        >
          {{ "generic.login" | translate }}
        </button>
        <div class="login-info" [innerHTML]="'login.info' | translate"></div>
        <div class="login-info cursor-pointer mt-5" routerLink="/imprint">Impressum</div>
      </form>
    </div>
  </div>
</div>
